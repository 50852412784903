
article.post {
  margin-bottom: 5em;
  max-width: 38em;

  header {
    margin: 4.7em 0 1.56em 0;

    h1 {
      font-size: 3em;
      letter-spacing: -0.02em;
      margin-bottom: 0;
      line-height: 1;
      color: 333;
    }

    p.post-date {
      font-size: .8em;
      margin-bottom: .3rem;
      font-style: italic;
      margin: .5em;
    }

  }

  &>p {
    line-height: 1.56;
    text-align: justify;
    margin: 0 0 1.56rem 0;
  }

// foreground-color: '#464646'
// h1: #111

  &>h1 {
    font-size: 1.95em;
    font-weight: 400;
    color: #555;
    letter-spacing: -0.01em;
    line-height: 1.2;
    border-bottom: 1px solid var(--accent-color);
    margin: 1.95rem 0 .8rem 0;
    padding: 0;
  }


  h2 {
    font-size: 1.56em;
    font-weight: 400;
    color: #555;
    letter-spacing: -0.03em;
    margin: 1.95rem 0 .8rem 0;
    padding: 0;
    line-height: 1;
    // text-decoration: underline;
  }

  h3 {
    font-size: 1.25em;
    font-weight: 400;
    color: #555;
    margin: 1.56rem 0 .8rem 0;
    padding: 0;
    line-height: 1;
  }

  li {
    margin: 0 0 .64rem 0;
    line-height: 1.56;
  }

  ul {
    margin: 0 0 1.56rem 1.95rem;
  }

  blockquote {
    color: var(--light-text-color);
    border-left: 1px solid var(--light-text-color);
    padding-left: .8rem;
    // margin: 0 0 1.56rem 0;
    margin: 1.56rem .8rem;
    line-height: 1.56;
    font-style: italic;
  }

  div.highlighter-rouge {
    line-height: 1.2;
    font-size: 1em;
    padding: .8rem;
    margin: 1.56rem .8rem;
    background-color: #eee;
    overflow-x: auto;
  }

  figure {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  img {
    max-width: 100%;
    margin: 0 auto;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
  }

  figcaption {
    text-align: center;
    font-style: italic;
    font-size: .9em;
    color: var(--light-text-color);
  }

}

@media(max-width: 600px) {
  .post header h1 {
      font-size: 2.8em;
  }

  .post ul {
    margin-left: 1.2rem;
    margin-right: .4rem;
  }

  .post p {
    text-align: left;
  }
}

@media(max-width: 360px) {
  .post header h1 {
      font-size: 2.2em;
  }
}
