// Basic site-wide Layout

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  border-bottom: 3px solid var(--accent-color);
  border-top: 3px solid var(--accent-color);
  overflow-x: hidden;
}

main {
  flex: 1 0 auto;
}

article {
  max-width: 38em;
  margin: 3em auto;
  padding: 0 1em;
}

article > header {
  margin-bottom: 2em;

  h1, h2 {
    text-align: center;
  }
}

article > section:not(:last-child) {
  margin-bottom: 3em;
}

section > header {
  margin-bottom: 1em;
}

// Custom page layouts

.two-columns {
  display: grid;
  grid-gap: 2em;

  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 699px) {
    grid-template-columns: 1fr;
  }
}

p:not(:last-child) {
  margin-bottom: 1em;
}

// Custom elements

ul.sidebar li {
  list-style-type: none;
  border-left: 1px solid var(--accent-color);
  margin-bottom: 1em;
}

.keyline-bottom {
  border-bottom: 1px solid var(--accent-color);
  line-height: 1.2;
  margin-bottom: 0.6em;
}

.skinny-center-justified {
  max-width: 30rem;
  margin: 0 auto;
  text-align: justify;
}

ul.strong-description {
  li {
    display: flex;
  }

  strong {
    padding-right: .8em;
    flex-grow: 0;
    flex-shrink: 0;
  }

  p {
    flex-grow: 1;
    padding-left: .8em;
    border-left: 1px solid var(--accent-color);
  }
}

// Place a p tag in between 2 empty divs.
.keyline-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  p {
    margin: 0 1em;
  }

  div {
    flex-grow: 1;
    background-color: var(--accent-color);
    content: "";
    height: 1px;
  }
}

// Mobile tweaks
@media (max-width: 650px) {
  ul.strong-description li {
    flex-direction: column;
  }
}

@media (min-width: 650px) {
  ul.strong-description {
    strong {
      flex-basis: 150px;
      text-align: right;
    }

    p {
      flex-basis: 400px;
    }
  }
}

