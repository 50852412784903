@import 'base';
@import 'layout';
@import 'typography';

@import 'post';
@import 'resume';
@import 'footer';
@import 'icons';

@import 'nav';
@import 'tabs';
@import 'project_showcase';
@import 'syntax_highlighting';

@import 'print';

// Color palette:
// This has to be in this particular file, because Jekyll won't substitute in 
// these variables otherwise.
:root {
  --accent-color: #743037;
  --accent-hover-color: #220043;
  --background-color: #FAFAFA;
  --foreground-color: #444;
  --light-text-color: #636363;
}

// Index Page
.circle-portrait {
  display: block;
  max-width: 80%;
  border-radius: 50%;
  margin: 2em auto 5em auto;
  overflow: hidden;

  * {
    width: 100%;
  }

  @media (max-width: 412px) {
    max-width: 300px;
    max-height: 300px;
    width: calc(100vw - 36px);
    height: calc(100vw - 36px); 
  }

  @media (min-width: 411px) {
    width: 300px;
    height: 300px;
  }
}

