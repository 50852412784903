// Print formatting, mostly for the resume

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  .page-break {
    display: block;
    page-break-before: always;
  }

  body > header, body > footer {
    display: none;
  }

  html body {
    font-size: 13px;
    border: none;
    background: none;
    // color: black;
  }

  p.muted {
    // color: black;
  }

  html dl.stacked dd {
    margin-bottom: 1.2em;
  }

  body article {
    margin: 2em .5em .5em .5em;
  }

  article.resume {
    max-width: 100%;

    p {
      margin-bottom: .5em;
    }

    p.subtitle {
      margin-bottom: 0;
    }

    & > header {
      margin-bottom: 2.5em;

      & > h2 {
        margin-bottom: 1em;
      }

      & > address {
        margin-top: 0;
        p {
          font-size: 1em;
        }
      }

    }

    & > section:not(:last-child) {
      margin-bottom: 1em;
    }

    & ul.strengths li {
      margin-bottom: .7em;
      display: block;

      strong {
        display: inline-block;
        vertical-align: top;
        width: 15%;
      }

      div {
        display: inline-block;
        width: 85%;
        // color: black;
      }
    }
  }

  section.two-columns {
    display: inline-block;
    width: 100%;

    section.column {
      display: inline-block;
      max-width: 48%;
      width: 48%;
    }

    section.column:first-child {
      float: left;
    }

    section.column:nth-child(2) {
      float: right;
    }
  }

}
