ul.project-showcase {
  margin: 0;

  & > li {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5em;
  }

  .name, .description {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .name {
    flex-basis: 200px;
    flex-grow: 1;
    padding-right: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-weight: bold;
    text-align: right;
    border-right: 1px solid var(--accent-color);
  }

  .description {
    flex-basis: 400px;
    flex-grow: 2;
    padding-left: 2em;
  }

  .showcase-image {
    width: 100%;
    // height: 100%; // For some reason chrome stretches images vertically if you don't set this. Firefox doesn't care.
    margin-bottom: 0em;
    padding: 0em;
    border-bottom: 1px solid var(--accent-color);

    * {
      width: inherit;
      height: inherit;
      display: block;
    }
  }

  .showcase-image-anchor {
    width: 100%
  }
}

@media(max-width: 670px) {
  // 670 px because that's where flex-wrap kicks in for the description and name (both their flex basis numbers plus article padding)
  // It's a magic number, I should find a better way to do this.  
  ul.project-showcase {

    .name {
      order: -1;
      border-top: 1px solid var(--accent-color);
    }

    .description {
      border-top: 1px solid var(--accent-color);
      border-left: 1px solid var(--accent-color);
      border-bottom: 1px solid var(--accent-color);
    }

    .showcase-image_border {
      border-top: 1px solid var(--accent-color);
      border-left: 1px solid var(--accent-color);
      border-right: 1px solid var(--accent-color);
      border-bottom: none;
    }


  }

}

dl.stacked {
    margin-bottom: 2em;

    dd {
        margin-left: 1em;
        margin-bottom: 2em;
        padding-left: 1em;
        border-left: 1px solid var(--accent-color);
    }
}

