.resume header {
  margin-bottom: 4em;

  h1,
  h2 {
    text-align: left;
    padding-left: 1rem;
    border-left: 1px solid var(--accent-color);
  }

  h1 {
    font-size: 3em;
    line-height: 1.3;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 2em;
    font-weight: 100;

    // Putting phrases in their own divs, so they wrap together.
    display: flex;
    flex-wrap: wrap;

    div {
      padding-right: 0.3em;
    }
  }

  p {
    text-align: inherit;
  }

  address {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
      line-height: 1;
      padding: 0 1.5em;
      font-size: 0.85em;
      text-align: center;
    }
  }
}

.resume h4 {
  padding: 0;
}

@media (max-width: 650px) {
  .resume header {
    h1 {
      font-size: 2.7em;
    }

    h2 {
      font-size: 1.7em;
    }
  }
}
