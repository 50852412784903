// footer

footer.main {
    flex-grow: 0;
    text-align: center;
    padding: 0 1em;
    margin-top: 1em;

    p {
        margin-bottom: 0;
        line-height: 1.3;
    }
}
