.icon {
  height: 1.4em;
  margin-right: .3em;

  &.icon-big {
    margin-top: 1em;
    height: 2em;
  }

  &.icon-color path {
    fill: var(--foreground-color);
    transition-duration: .9s;
  }
}

.icon-inline {
  height: 1em;
  margin-right: .3em;
}

a:hover .icon-color path {
  transition-duration: .2s;
  fill: var(--accent-hover-color);
}

// People don't want me altering their logos, so the hover states are background-color changes
.icon-logo {
  border-radius: 50%;
  background-color: var(--background-color);
  transition-duration: .9s;
}

a:hover .icon-logo {
  background-color: var(--accent-color);
  transition-duration: .2s;
}

ul.tech-list {
  margin: 0;
  font-size: 1.1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.center {
    justify-content: center;
  }

  li {
    display: flex;
    align-items: center;
    margin: 0 .7em .8em .7em;
  }
}
