// navbar

body > header {
  width: 100vw;

  a:focus {
    outline: none;
  }

  a:focus span:nth-child(2) {
    text-decoration: underline;
  }
}

nav.main {
  position: relative;

  // This is the moving highlight:
  div.navbar-tab {
    background-color: var(--accent-color);
    position: absolute;
    z-index: 10;
    box-shadow: 2px 1px 6px #0006;
    border-radius: 0 0 2px 2px;

    // We start it with approximately the correct size, because scaling affects
    // the box shadow.
    width: 80px;
    height: 20px;
    transform-origin: 0 0;

    // Initialize it off the screen, position with javascript once loaded:
    left: -100px;
    top: -100px;
  }

  .slide-transition {
    transition-property: transform;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    z-index: 20;
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: var(--foreground-color);
  }

  .active {
    color: var(--background-color);

    svg path {
      fill: var(--background-color);
    }
  }

  li:not(.active):hover {
    color: var(--accent-hover-color);
  }

  a {
    color: inherit;
    background-color: inherit;

    &:hover {
      color: inherit;
    }
  }

  li.dropdown-parent {
    display: flex;
    flex-direction: column;
  }

  ul.nav-dropdown {
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: 0.4s;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
    top: 2em;

    li,
    a {
      transition-property: text-shadow, text-decoration;
      transition-duration: 0.2s;
    }

    a:focus,
    a:active {
      outline: none;
      // text-shadow: 0 0 9px var(--light-text-color);
      text-decoration: underline;
    }
  }

  li.dropdown-parent:hover > ul,
  li.dropdown-parent:focus-within > ul,
  ul.nav-dropdown:hover {
    // display: flex;
    visibility: visible;
    opacity: 1;
  }
}

// Desktop styles:
@media (min-width: 601px) {
  nav.main {
    padding: 0 1em 1em 1em;

    ul {
      flex-direction: row;
      justify-content: end;
      align-items: flex-start;
    }

    a {
      padding: 0 1em;
    }

    // Move first item (homepage link) to the left
    li:first-child {
      margin-right: auto;
    }
  }

  // Hide the hamburger menu on desktop.
  button.hamburger {
    display: none;
  }
}

// Mobile styles
@media (max-width: 600px) {
  button.hamburger {
    position: fixed;
    right: 20px;
    bottom: 60px;
    width: 50px;
    height: 50px;
    z-index: 20;
    background-color: var(--accent-color);
    box-shadow: 3px 3px 20px #00000080;
    border-radius: 50%;
    overflow: hidden;
    font-size: 0;

    // 3 spans make up the lines of the burger menu. Could be done with SVG, but
    // this is not so complicated.
    span {
      position: absolute;
      height: 1px;
      width: 24px;
      right: 13px;
      background-color: var(--background-color);
      transform-origin: left;
      transition-property: transform;
      transition-duration: 150ms;
    }

    :nth-child(1) {
      top: 17px;
    }

    :nth-child(2) {
      top: 25px;
    }

    :nth-child(3) {
      top: 33px;
    }
  }

  button.hamburger.active {
    // If I trig functions in CSS, I wouldn't have to hardcode these values.
    :nth-child(1) {
      transform: translateX(3.1px) rotate(41.8deg);
    }

    :nth-child(2) {
      transform: translateX(-100px);
    }

    :nth-child(3) {
      transform: translateX(3.1px) rotate(-41.8deg);
    }
  }

  nav.main {
    // Initialize the menu closed.
    transform: scaleX(0);
    position: fixed;
    right: 45px;
    left: 45px;
    bottom: 85px;
    z-index: 10;
    overflow: hidden;
    background-color: var(--background-color);
    border: 1px solid var(--accent-color);
    box-shadow: 3px 3px 15px #0009;
    transition: transform 100ms 80ms;
    transform-origin: right bottom;

    // Fixes disappearing border on chrome
    margin: 1px;

    ul {
      flex-direction: column;
      padding: 1em;
    }

    a {
      display: flex;
      align-items: baseline;
      justify-content: center;

      width: 100%;

      & > span:first-child {
        flex: 33%;
        text-align: right;
      }

      & > span:nth-child(2) {
        flex: 66%;
        font-size: 1.3em;
      }
    }

    li {
      width: 100%;
    }

    li:not(:last-child) {
      margin-bottom: 1.5em;
    }

    &.open {
      transform: scaleX(1);
    }

    .icon {
      height: 1em;
    }
  }
}

nav.social-media {
  display: flex;
  justify-content: center;

  a {
    display: inline-block;
    margin: 0 1rem;
    transition-property: fill-color;
    transition-duration: 0.2s;
  }
}
