// Basic, site-wide typography & style
html {
  font-size: 18px;
}

@font-face {
  font-family: "Nunito";
  font-display: fallback;
  font-style: normal;
  font-weight: 200;
  src: local("Nunito ExtraLight"), local("Nunito-ExtraLight"),
    url(https://fonts.gstatic.com/s/nunito/v9/XRXW3I6Li01BKofA-seUYevI.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(https://fonts.gstatic.com/s/nunito/v9/XRXV3I6Li01BKofINeaB.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: "Nunito", sans-serif;
  line-height: 1.8;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

h1,
h2,
h3,
h4 {
  // text-shadow: 2px 2px 4px #0002;
  padding: 0 0.5em;
}

h1 {
  font-size: 5em;
  line-height: 1.5;
  font-weight: 200;
}

h2 {
  font-weight: 400;
  font-size: 2.5em;
  line-height: 1.5;
}

h3 {
  margin-top: 2em;
  font-size: 1.7em;
  font-weight: 400;
}

h4 {
  font-weight: 200;
  font-size: 1.3em;
  font-weight: 400;
}

strong {
  font-weight: 900;
  color: #333;
}

em {
  font-style: italic;
}

code {
  font-family: monospace;
  font-size: 0.8em;
  background: #eee;
  padding: 0.1em 0.5em;
}

p {
  font-size: 1em;
}

a,
button {
  color: var(--accent-color);
  text-decoration: none;
  transition-property: color;
  transition-duration: 0.4s;

  &:hover {
    color: var(--accent-hover-color);
  }
}

ul {
  margin-left: 2em;

  list-style: disc;

  li {
    margin-bottom: 1em;
  }
}

// Special elements

.subtitle {
  font-style: italic;
  font-size: 1.1em;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.muted {
  // font-size: .9em;
  line-height: 1.6;
  color: var(--light-text-color);
}

.light-text {
  color: var(--light-text-color);
}

.nowrap {
  white-space: nowrap;
}

// Use smaller fonts on mobile:
@media (max-width: 600px) {
  h1 {
    font-size: 2.8em;
  }

  h2 {
    font-size: 2em;
  }
}
