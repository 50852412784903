// Basic styles for the whole site

@import 'reset';

// Page load transition
.transition-fade {
  transition: opacity 80ms;
  opacity: 1;
}

html.is-animating .transition-fade{
  opacity: 0;
}

